import React, { Component } from "react";
import { StoreContext } from "../context";
import Loading from "./Loading";
import Store from "./Store";
import Section from "./Section";

export default class FeaturedStores extends Component {
  static contextType = StoreContext;
  render() {
    let { loading, featuredStores: stores } = this.context;
    stores = stores.map((store) => {
      return <Store key={store.id} store={store} previewHeight={"large"} />;
    });
    return (
      <Section
        // "" = one column, "three" = three columns
        content=""
        // "" = 1. description and 2. content side by side, "reversed" = side by side, reversed order, "vertical" = one after the other
        grid=""
        // "" = automatically justified (for example, the sticky featured stores description would be at the top of section-description-wrap), "center" = content will be aligned to the middle of the div (.align)
        type=""
        // "" = left justified, "sticky" = sticky, "center" = centered to the body (can combine "sticky" and "center")
        subType="sticky"
        description="Featured designer and streetwear stores across Canada."
        link="/stores"
        btnStyle="secondary"
        btnText="See all stores"
      >
        <>{loading ? <Loading /> : stores}</>
      </Section>
    );
  }
}
