import React from "react";
import SectionDescription from "./SectionDescription";

export default function Section(props) {
  return (
    <section className={`section-wrap ${props.grid}`}>
      <SectionDescription
        type={props.type}
        subType={props.subType}
        description={props.description}
        link={props.link}
        btnStyle={props.btnStyle}
        btnText={props.btnText}
      ></SectionDescription>
      <div className={`section-content-grid ${props.content}`}>
        {props.children}
      </div>
    </section>
  );
}
