import React from "react";

export default function Banner({ children, title, subtitle }) {
  return (
    <div className="banner">
      <div id="banner-text" className="banner-text-wrap">
        <h1>{title}</h1>
      </div>
      <p>{subtitle}</p>
      {children}
    </div>
  );
}
