import styled from "styled-components";
import defaultHero from "../images/hero-haven.jpg";

const StyledHero = styled.header`
  padding: 232px 0px 168px 0px;
  position: relative;
  min-height: 50vh;
  top: -64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: var(--heroGradientWebkit), url(${props => props.img ? props.img : defaultHero});
  background-image: var(--heroGradient), url(${props => props.img ? props.img : defaultHero});
  background-position: 50% 50%;
  background-size: cover;
  @media only screen and (max-width: 992px) {
    padding: 144px 32px 80px 32px;
    min-height: 40vh;
  }
`;

export default StyledHero;
