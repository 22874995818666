import React from "react";

export default function ExternalButton(props) {
  return (
    <a href={props.link} target={props.external ? "_blank" : "_self"}className={`btn ${props.btnStyle}`}>
      <div className={`btn-link ${props.btnStyle}`}>
        <div className="btn-text">{props.btnText}</div>
      </div>
      {props.btnStyle === "primary" ? <div className="btn-shadow"></div> : ""}
    </a>
  );
}

ExternalButton.defaultProps = {
  btnText: "Button",
  btnStyle: "primary",
  external: true
};
