import React, { Component } from "react";
import defaultBcg from "../images/PlaceholderBG.jpg";
import Banner from "../components/Banner";
import { StoreContext } from "../context";
import Button from "../components/Button";
import ExternalButton from "../components/ExternalButton";
import StyledHero from "../components/StyledHero";
import { Link } from "react-router-dom";

export default class SingleStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
      defaultBcg,
    };
  }
  static contextType = StoreContext;

  render() {
    const { getStore } = this.context;
    const store = getStore(this.state.slug);
    if (!store) {
      return (
        <div className="banner error">
          <p>No such store could be found</p>
          <Button link="/stores" btnText="Return to stores" />
        </div>
      );
    }
    const {
      name,
      parent,
      city,
      citySlug,
      // designers,
      // designersSlug,
      designersObj,
      facebook,
      instagram,
      twitter,
      type,
      gender,
      website,
      location,
      images,
    } = store;
    const [mainImg] = images;
    return (
      <>
        <StyledHero hero="storeHero" img={mainImg || this.state.defaultBcg}>
          <Banner title={parent}>{website === undefined ? '' : (<ExternalButton
              link={website}
              external={true}
              btnText="Visit site"
            ></ExternalButton>)}
          </Banner>
        </StyledHero>
        <section className="section-wrap single-store">
          <div className="section-description-wrap">
            <div className="section-description">
              <article className="info-wrap">
                <h2 className="extra-margin-medium">Location</h2>
                <p className="tag-small" id={citySlug} value={citySlug}>
                  {city}
                </p>
                <p className="extra-margin-small">{location}</p>
                {location === undefined ? (
                  <p>
                    {city === "Online Only"
                      ? "Visit the site to shop."
                      : "Address coming soon! Still working on getting all the data."}
                  </p>
                ) : (
                  <ExternalButton
                    link={`http://maps.google.com/?q=${name} ${location}`}
                    external={true}
                    btnText="Directions"
                    btnStyle="secondary"
                  ></ExternalButton>
                )}
              </article>
              <article className="info-wrap">
                <h2 className="extra-margin-medium">Category</h2>
                <div className="section-content-grid two single-store">
                  <div>
                    <p className="tag-small" id={gender} value={gender}>
                      Gender
                    </p>
                    <p>{gender.join(" + ")}</p>
                  </div>
                  <div>
                    <p className="tag-small" id={type} value={type}>
                      Type
                    </p>
                    <p>{type}</p>
                  </div>
                </div>
              </article>
              <article className="info-wrap">
                <h2 className="extra-margin-medium">Social</h2>
                <p className="tag-small">Follow {parent}</p>
                <div className="section-content-grid three single-store">
                  {instagram === undefined ? (
                    ""
                  ) : (
                    <div className="info-inner-wrap">
                      <ExternalButton
                        link={instagram}
                        external={true}
                        btnText="Instagram"
                        btnStyle="secondary"
                      ></ExternalButton>
                    </div>
                  )}
                  {facebook === undefined ? (
                    ""
                  ) : (
                    <div className="info-inner-wrap">
                      <ExternalButton
                        link={facebook}
                        external={true}
                        btnText="Facebook"
                        btnStyle="secondary"
                      ></ExternalButton>
                    </div>
                  )}
                  {twitter === undefined ? (
                    ""
                  ) : (
                    <div className="info-inner-wrap">
                      <ExternalButton
                        link={twitter}
                        external={true}
                        btnText="Twitter"
                        btnStyle="secondary"
                      ></ExternalButton>
                    </div>
                  )}
                </div>
              </article>
            </div>
          </div>
          <div className="section-content-outer">
            <div className="box-wrap">
              <div className="box-inner-wrap">
                <h2 className="extra-margin-medium">Popular Designers</h2>
                {console.log(designersObj)}
                {designersObj.length === 0 ? (
                  <p>
                    Designer list coming soon! For now, visit the store's
                    website.
                  </p>
                ) : (
                  <div className="form-wrap single-store less-row-gap">
                    {designersObj.map((item) => {
                      return (
                        <Link
                          key={item.slug}
                          className="tag-capitalize cut"
                          to={`/stores?designers=${item.slug}`}
                        >
                          <p key={item.slug} value={item.slug}>
                            {item.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="box-wrap-shadow"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
