import React from "react";
import Button from "./Button";

export default function SectionDescription(props) {
  return (
    <div
      className={`section-description-wrap ${
        props.type === "center" ? "align" : ""
      }`}
    >
      <div
        className={`section-description ${
          props.subType.includes("center") ? "center half" : ""
        } ${props.subType.includes("sticky") ? "sticky" : ""}`}
      >
        <h2 className="extra-margin-large">{props.description}</h2>
        <Button
          link={props.link}
          btnStyle={props.btnStyle}
          btnText={props.btnText}
        ></Button>
      </div>
    </div>
  );
}