import React from "react";
import loadingGif from "../images/gif/loading-simple.gif";

export default function Loading() {
  return (
    <div className="center extra-margin-top">
      <h2 className="extra-margin-large">Stores loading...</h2>
      <img src={loadingGif} alt="" />
    </div>
  );
}
