import React, { Component } from "react";
import logo from "../images/su-logo-white.svg";
import { Link } from "react-router-dom";
import ExternalButton from "./ExternalButton";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-wrap extra-margin-top">
        <div className="section-wrap less-padding footer">
          <Link to="/" className="logo-wrap">
            <img src={logo} alt="Shop Unknown" />
          </Link>
          <div className="white-text">
            Hi!{" "}
            <span role="img" aria-label="Waving Hand">
              👋
            </span>
            <br />
            <br />
            <br />
            This site is a personal passion project of mine to help me learn
            programming, and it is strictly for demonstration purposes only.
            <br />
            <br />
            <br />
            Please{" "}
            <a href="mailto: jakefilms99@gmail.com" className="white-text">
              email
            </a>{" "}
            me if you would like any of the content or images to be removed.
            <br />
            <br />
            <br />
            <ExternalButton
              link="https://www.jakerosenthal.ca/projects/shop-unknown"
              external={true}
              btnText="Read about this project"
              btnStyle="secondary-white"
            ></ExternalButton>
          </div>
        </div>
      </div>
    );
  }
}
