import React from "react";
import Banner from "../components/Banner";
import Button from "../components/Button";
import FeaturedStores from "../components/FeaturedStores";
import FeaturedDesigners from "../components/FeaturedDesigners";
import FeaturedCities from "../components/FeaturedCities";
import StyledHero from "../components/StyledHero";

export const Home = () => {
  return (
    <>
      <StyledHero>
        <Banner
          title={[
            "The best way to discover and find your",
            <em key={Math.floor(Math.random() * 1000)}> favourite </em>,
            "brands.",
          ]}
        >
          <Button link="/stores" btnText="See all stores"></Button>
        </Banner>
      </StyledHero>
      <FeaturedStores />
      <FeaturedDesigners />
      <FeaturedCities />
    </>
  );
};
