import React from "react";
import { Link } from "react-router-dom";

export default function Card(props) {
  let citySpecificIndex = props.item.name.lastIndexOf(":");
  let citySpecific;
  if (citySpecificIndex !== -1) {
    citySpecific = props.item.name
      .substring(citySpecificIndex)
      .replace(":", "-");
  } else {
    citySpecific = "";
  }
  return (
    <div className="details-wrap left">
      {/* <div className={"details-wrap " + (window.innerWidth < 768 ? 'center' : 'left')}> */}
      <div>
        <div className="tag-small">
          {props.item.city === undefined
            ? `${props.item.storeCount} ${
                props.item.storeCount > 1 ? "stores" : "store"
              }`
            : `${props.item.city} ${citySpecific}`}
        </div>

        {props.item.city === undefined ? (
          <div className="card-title-no-wrap">{props.item.name}</div>
        ) : (
          <Link className="card-link" to={`/stores/${props.item.slug}`}>
            <div className="card-title">{props.item.parent}</div>
          </Link>
        )}
      </div>
      {props.children}
    </div>
  );
}

Card.defaultProps = {
  city: "City",
  storeCount: "Store count",
  name: "Store name",
};
