import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  return (
    <Link to={props.link} className={`btn ${props.btnStyle}`}>
      <div className={`btn-link ${props.btnStyle}`}>
        <div className="btn-text">{props.btnText}</div>
      </div>
      {props.btnStyle === "primary" ? <div className="btn-shadow"></div> : ""}
    </Link>
  );
}

Button.defaultProps = {
  btnStyle: "primary",
  link: "/",
  btnText: "Button",
};
