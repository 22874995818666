import React, { Component } from "react";
import logo from "../images/su-logo-blue.svg";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";

export default class Navbar extends Component {
  state = {
    isOpen: false,
    opacity: 0,
  };

  listenScrollEvent = (e) => {
    let scrollLevel = window.scrollY;
    let bannerElem = document.getElementById("banner-text");
    let opacityLevel = 0;
    let changeDurationLevel = 100;
    let headerLevel;
    let changeAtLevel;
    if (bannerElem !== null) {
      headerLevel = bannerElem.offsetTop - 16;
      changeAtLevel = headerLevel - changeDurationLevel;
    }
    let checkHero = document.getElementsByTagName("header");
    if (checkHero.length < 1) {
      opacityLevel = 1;
    } else {
      if (scrollLevel === 0) {
        opacityLevel = 0;
      } else if (scrollLevel >= changeAtLevel && scrollLevel <= headerLevel) {
        opacityLevel = (scrollLevel - changeAtLevel) / 100;
      } else if (scrollLevel >= headerLevel) {
        opacityLevel = 1;
      }
    }
    this.setState({ opacity: opacityLevel });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  handleToggle = () => {
    if (window.innerWidth < 768) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };
  render() {
    return (
      <nav className="sticky-nav">
        <div
          className="nav-background"
          style={{ opacity: this.state.opacity }}
        ></div>
        <div className="nav-grid">
          <div className="nav-header">
            <Link to="/" className="logo-wrap">
              <img className="logo" src={logo} alt="Shop Unknown" />
            </Link>
            <button
              type="button"
              className="nav-btn"
              onClick={this.handleToggle}
            >
              <FiMenu className="nav-icon" />
            </button>
          </div>
          <ul
            className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}
          >
            <li></li>
            <li>
              <Link
                to="/stores"
                className="btn-text"
                onClick={this.handleToggle}
              >
                Stores
              </Link>
            </li>
            <li>
              <Link
                to="/designers"
                className="btn-text"
                onClick={this.handleToggle}
              >
                Designers
              </Link>
            </li>
            <li>
              <Link
                to="/cities"
                className="btn-text"
                onClick={this.handleToggle}
              >
                Cities
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
