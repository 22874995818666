import React from "react";
import TagContainer from "../components/TagContainer";

export const Designers = (props) => {
  return (
    <>
      <TagContainer type="designers" props={props} />
    </>
  );
};
