import React from "react";
import StoreContainer from "../components/StoreContainer";

export const Stores = (props) => {
  return (
    <>
      <StoreContainer props={props} />
    </>
  );
};
