import React from "react";
import StoreList from "./StoreList";
import { withStoreConsumer } from "../context";
import Loading from "./Loading";
import StoreFilterSort from "./StoreFilterSort";

function StoreContainer({ context, props }) {
  const { loading, sortedStores, stores } = context;
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="section-wrap no-grid less-padding">
      <div className="grid-spacer"></div>
      <h1 className="extra-margin-large center">
        Stores - {sortedStores.length} Results
      </h1>
      <StoreFilterSort stores={stores} props={props} />
      <StoreList stores={sortedStores} />
    </div>
  );
}

export default withStoreConsumer(StoreContainer);
