import React from "react";

const Pagination = ({ storesPerPage, totalStores, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalStores / storesPerPage); i++) {
    pageNumbers.push(i);
  }

  // console.log(currentPage);

  return (
    <nav>
      <div className="numbers-wrap center extra-margin-top">
        {pageNumbers.map((number) => (
          <a
            key={number}
            onClick={(e) => {
              paginate(number);
              window.scrollTo(0, 0);
              e.preventDefault();
            }}
            href="!#"
            className={"number" + (currentPage === number ? " active" : "")}
            // className="number"
          >
            {number}
          </a>
        ))}
      </div>
    </nav>
  );
};

export default Pagination;
