import React, { useEffect } from "react";
import { useContext } from "react";
import { StoreContext } from "../context";

// Unique values
const getUnique = (items, value) => {
  return [...new Set(items.map((item) => item[value]))];
};

export default function StoreFilterSort({ stores, props }) {
  const context = useContext(StoreContext);
  const {
    handleChange,
    handleUrl,
    type,
    gender,
    city,
    search,
    order,
    designers,
  } = context;

  const query = props.location.search;
  const params = new URLSearchParams(query);
  let paramsObj = {
    type: params.get("type") !== null ? params.get("type") : "All",
    gender: params.get("gender") !== null ? params.get("gender") : "All",
    city: params.get("city") !== null ? params.get("city") : "All",
    search: params.get("search") !== null ? params.get("search") : "",
    order:
      params.get("order") !== null ? params.get("order") : "Store Name (A-Z)",
    designers:
      params.getAll("designers") !== []
        ? params.getAll("designers")
        : designers,
  };

  useEffect(() => {
    if (query !== "") {
      handleUrl(paramsObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(props.history.location);
  props.history.listen((location, action) => {
    if (location.pathname === "/stores" || location.pathname.includes("?")) {
      window.location.reload();
    }
  }, []);
  // props.history.listen((location, action) => {
  //   console.log(location.pathname);
  //   console.log(location.search);
  //   if (location.pathname === "/stores" || location.search === "") {
  //     window.location.reload();
  //   }
  // }, []);
  //   if (location.pathname === "/stores" || location.search === "") { // Change to && to disable refresh on SingleStore
  //     window.location.reload();
  //   }
  // }, []);

  // Tags
  let tagsObj = [];

  function Tag(name, slug, prefix) {
    this.name = name;
    this.slug = slug;
    this.prefix = prefix + slug;
    this.link = "/stores" + query.replace(this.prefix, "");
  }

  function tags(obj) {
    function tagAdd(name, slug, prefix) {
      tagsObj.push(new Tag(name, slug, prefix));
    }
    if (obj.type !== "All") {
      tagAdd(obj.type, encodeURIComponent(obj.type).replace(/%20/g, "+"), "&type=");
    }
    if (obj.gender !== "All") {
      tagAdd(obj.gender, obj.gender, "&gender=");
    }
    if (obj.city !== "All") {
      if (query.startsWith("?city=")) {
        let prefix = "city=";
        tagAdd(obj.city, obj.city, prefix);
      } else {
        let prefix = "&city=";
        tagAdd(obj.city, obj.city, prefix);
      }
    }
    if (obj.search !== "") {
      tagAdd(obj.search, encodeURIComponent(obj.search).replace(/%20/g, "+"), "search=");
    }
    if (obj.order !== "Store Name (A-Z)") {
      tagAdd(
        obj.order,
        encodeURIComponent(obj.order)
          .replace(/%20/g, "+")
          .replace("(", "%28")
          .replace(")", "%29"),
        "&order="
      );
    }
    if (obj.designers !== []) {
      for (let x in obj.designers) {
        let slug = obj.designers[x];
        let name =
          designers[designers.findIndex((i) => i.slug === slug)]["name"];
        if (query.startsWith("?designers=")) {
          let prefix = "designers=";
          tagAdd(name, slug, prefix);
        } else {
          let prefix = "&designers=";
          tagAdd(name, slug, prefix);
        }
      }
    }
  }
  tags(paramsObj);

  // Unique types
  let types = getUnique(stores, "type");
  types = ["All", ...types];
  types = types.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  // Unique genders
  let genders = ["All", "Men", "Women"];
  genders = genders.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  // Unique cities
  let cities = getUnique(stores, "city");
  cities = ["All", ...cities];
  cities = cities.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  // Unique sort;
  let orders = [
    "Store Name (A-Z)",
    "Store Name (Z-A)",
    "Designer CNT (L-H)",
    "Designer CNT (H-L)",
  ];
  orders = orders.map((item, index) => {
    return (
      <option value={item} key={index}>
        {item}
      </option>
    );
  });

  return (
    <section>
      <div className="center">
        <div
          className="btn secondary button"
          onClick={() => {
            document
              .getElementById("form-outer-wrap")
              .classList.contains("hidden")
              ? document
                  .getElementById("form-outer-wrap")
                  .classList.remove("hidden")
              : document
                  .getElementById("form-outer-wrap")
                  .classList.add("hidden");
            document
              .getElementById("form-outer-wrap")
              .classList.contains("hidden")
              ? (document.getElementById("btn-close-text").innerHTML =
                  "Open filters")
              : (document.getElementById("btn-close-text").innerHTML =
                  "Close filters");
          }}
        >
          <div className="btn-link secondary">
            <div className="btn-text" id="btn-close-text">
              Open filters
            </div>
          </div>
        </div>
      </div>
      <div className="form-outer-wrap hidden" id="form-outer-wrap">
        {/* <div className="set-width"> */}
        <form className="form-wrap extra-margin-large" id="storeFilter">
          {/* <form className="section-content-grid min-gap set-width form-wrap" id="storeFilter"> */}
          {/* search*/}

          <div className="form-group">
            <label htmlFor="search">Search</label>
            <input
              type="text"
              name="search"
              value={search}
              onChange={handleChange}
            />
          </div>
          {/*end search*/}
          {/*sort*/}
          <div className="form-group">
            <label htmlFor="sort">Sort</label>
            <select
              name="order"
              id="order"
              value={order}
              onChange={handleChange}
            >
              {orders}
            </select>
          </div>
          {/* end sort*/}
          {/*city*/}
          <div className="form-group">
            <label htmlFor="city">City</label>
            <select name="city" id="city" value={city} onChange={handleChange}>
              {cities}
            </select>
          </div>
          {/* end city*/}
          {/*type*/}
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select name="type" id="type" value={type} onChange={handleChange}>
              {types}
            </select>
          </div>
          {/* end type*/}
          {/*gender*/}
          <div className="form-group">
            <label htmlFor="type">Gender</label>
            <select
              name="gender"
              id="gender"
              value={gender}
              onChange={handleChange}
            >
              {genders}
            </select>
          </div>
          {/* end gender*/}
          {/*designers*/}
          <div className="form-group designers-wrap">
            <label htmlFor="designers-multi">Designers</label>
            <div className="designers-scroll form-wrap less-row-gap">
              {designers.map((item) => {
                return (
                  <div className="checkbox" key={item.slug}>
                    <input
                      type="checkbox"
                      name="designers"
                      id={item.slug}
                      value={item.slug}
                      checked={item.isChecked}
                      onChange={handleChange}
                    />
                    <label className="tag-capitalize bold" htmlFor={item.name}>
                      {item.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {/* end designers*/}
        </form>
        <div className="center extra-margin-large">
          <div>
            <button
              className="btn-link primary invert btn-text extra-margin-small"
              type="submit"
              form="storeFilter"
            >
              Apply
            </button>
          </div>
          <a className="clear-btn" href="/stores">
            Clear
          </a>
        </div>
        {/* tags */}
      </div>
      <div className="tag-outer-wrap">
        {tagsObj.map((item) => {
          return (
            <a className="tag-wrap" href={item.link} key={item.slug}>
              <span className="close-tag">✕</span>
              <span className="tag-capitalize">{item.name}</span>
            </a>
          );
        })}
        {tagsObj.length !== 0 ? (
          <a className="clear-btn small" href="/stores">
            Clear
          </a>
        ) : (
          <div className="clear-div"></div>
        )}
      </div>
      {/* end tags*/}
    </section>
  );
}
