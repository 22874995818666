import React from "react";
import TagContainer from "../components/TagContainer";

export const Cities = (props) => {
  return (
    <>
      <TagContainer type="cities" props={props} />
    </>
  );
};
