import React, { Component } from "react";
// import items from "./data";
import Client from "./Contentful";
import { withRouter } from "react-router-dom";

const StoreContext = React.createContext();
class StoreProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      sortedStores: [],
      featuredStores: [],
      loading: true,
      type: "",
      gender: "All",
      city: "All",
      designers: [],
      featuredDesigners: [],
      cities: [],
      featuredCities: [],
      search: "",
      order: "Store Name (A-Z)",
    };
  }
  // getData
  getData = async () => {
    try {
      let response = await Client.getEntries({
        content_type: "stores",
        order: "fields.name",
        limit: 300,
      });
      // console.log(response);
      // Stores
      let stores = this.formatData(response.items);
      // console.log(stores);
      let featuredStores = stores.filter((store) => store.featured === true);
      let includes = this.formatIncludes(response.includes.Entry);
      // Designers
      let designers = includes.filter(
        (designer) => designer.space === "designers"
      );
      designers.forEach((item) => {
        let counter = 0;
        for (let x in stores) {
          if (stores[x]["designersSlug"].includes(item.slug)) {
            counter++;
          }
        }
        item.isChecked = false;
        item.storeCount = counter;
      });

      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      }

      designers.sort(compare);

      let featuredDesigners = designers.filter(
        (designer) => designer.featured === true
      );
      // Cities
      let cities = includes.filter((city) => city.space === "cities");
      cities.forEach((item) => {
        let counter = 0;
        for (let x in stores) {
          if (stores[x]["citySlug"] === item.slug) {
            counter++;
          }
        }
        item.storeCount = counter;
      });

      cities.sort(compare);

      let featuredCities = cities.filter((city) => city.featured === true);

      this.setState({
        stores,
        featuredStores,
        sortedStores: stores,
        loading: false,
        designers,
        featuredDesigners,
        cities,
        featuredCities,
      });
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    this.getData();
  }

  formatData(items) {
    let tempItems = items.map((item) => {
      let id = item.sys.id;
      let type = item.fields.type[0];
      let images;
      if (typeof item.fields.images !== "undefined") {
        images = item.fields.images.map((image) => image.fields.file.url);
      } else {
        images = []
      }
      let designers;
      let designersSlug;
      if (typeof item.fields.designers !== "undefined") {
        designers = item.fields.designers.map(
          (designer) => designer.fields.name
        );
        designersSlug = item.fields.designers.map(
          (designer) => designer.fields.slug
        );
      } else {
        designers = [];
        designersSlug = [];
      }
      let designersObj = designers.map((x, index) => ({
        name: x,
        slug: designersSlug[index],
      }));
      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      }

      designersObj.sort(compare);

      let city = item.fields.city.fields.name;
      let citySlug = item.fields.city.fields.slug;
      let store = {
        ...item.fields,
        images,
        id,
        designers,
        designersSlug,
        designersObj,
        city,
        citySlug,
        type,
      };
      return store;
    });
    return tempItems;
  }

  formatIncludes(items) {
    let tempItems = items.map((item) => {
      let id = item.sys.id;
      let name = item.fields.name;
      let parent = item.fields.parent;
      let slug = item.fields.slug;
      let featured = item.fields.featured;
      let space = item.sys.contentType.sys.id;
      let itemObj = {
        id,
        name,
        parent,
        slug,
        featured,
        space,
      };
      return itemObj;
    });
    return tempItems;
  }

  getStore = (slug) => {
    let tempStores = [...this.state.stores];
    const store = tempStores.find((store) => store.slug === slug);
    return store;
  };

  handleUrl = (obj) => {
    this.setState(
      {
        type: obj.type,
        gender: obj.gender,
        city: obj.city,
        search: obj.search,
        order: obj.order,
      },
      this.filterStores
    );
    if (obj.designers !== []) {
      let currentCB = [...this.state.designers];
      for (let x in obj.designers) {
        let objElemID = obj.designers[x];
        let currentIndex = currentCB.findIndex((obj) => obj.slug === objElemID);
        currentCB[currentIndex].isChecked = true;
      }
      this.setState(
        {
          designers: currentCB,
        },
        this.filterStores
      );
    }
  };

  handleChange = (event) => {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    const elemID = event.target.id;
    if (target.type === "checkbox") {
      let currentCB = [...this.state.designers];
      let currentIndex = currentCB.findIndex((obj) => obj.slug === elemID);
      currentCB[currentIndex].isChecked = value;
      this.setState(
        {
          designers: currentCB,
        }
        // this.filterStores
      );
    } else {
      value = target.value;
      this.setState(
        {
          [name]: value,
        }
        // this.filterStores
      );
    }
  };

  filterStores = () => {
    let { stores, type, gender, city, search, order, designers } = this.state;
    // Retrieve all stores
    let tempStores = [...stores];

    // Filter by type
    if (type !== "All") {
      tempStores = tempStores.filter((store) => store.type === type);
    }

    // Filter by city
    if (city !== "All") {
      tempStores = tempStores.filter((store) => store.city === city);
    }

    // Filter by gender
    if (gender !== "All") {
      tempStores = tempStores.filter((store) => store.gender.includes(gender));
    }

    // Filter by designer
    let tempDesignersObj = designers.filter(
      (designer) => designer.isChecked === true
    );

    let tempDesignersList = tempDesignersObj.map((designer) => designer.slug);

    let designerIndex = designers.findIndex((obj) =>
      obj.name.toLowerCase().match(search.toLowerCase())
    );

    if (designerIndex !== -1 && search.length > 2) {
      let designerSearch = designers[designerIndex]["slug"];
      tempDesignersList.push(designerSearch);
    }

    if (tempDesignersList.length !== 0 || tempDesignersObj.length !== 0) {
      tempStores = tempStores.filter((store) =>
        tempDesignersList.some((arr) => store.designersSlug.includes(arr))
      );
    }

    // Filter by search
    if (search !== "") {
      const searchValue = search.toLowerCase();
      tempDesignersList.push(searchValue);
      tempStores = tempStores.filter((store) => {
        const storeGenders = store.gender.map((item) => item.toLowerCase());
        if (
          store.name.toLowerCase().includes(searchValue) ||
          store.type.toLowerCase().match(searchValue) ||
          store.city.toLowerCase().match(searchValue) ||
          storeGenders.includes(searchValue) ||
          tempDesignersList.some((arr) => store.designersSlug.includes(arr))
        ) {
          return true;
        }
        return false;
      });
      // return tempStores;
    }

    // Sort
    tempStores.sort(function (a, b) {
      let x = a.name.toLowerCase();
      let y = b.name.toLowerCase();
      let direction;
      if (order === "Store Name (A-Z)" || order === "Designer CNT (L-H)") {
        direction = 1;
      } else {
        direction = -1;
      }
      if (order.includes("Store Name")) {
        return direction * (x < y ? -1 : x > y ? 1 : 0);
      } else if (order.includes("Designer CNT")) {
        return direction * (a.designers.length - b.designers.length);
      }
      return tempStores;
    });

    // Change state
    this.setState({
      sortedStores: tempStores,
    });
  };

  render() {
    return (
      <StoreContext.Provider
        value={{
          ...this.state,
          getStore: this.getStore,
          handleChange: this.handleChange,
          handlePreSelect: this.handlePreSelect,
          handleUrl: this.handleUrl,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

const StoreConsumer = StoreContext.Consumer;

export function withStoreConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <StoreConsumer>
        {(value) => <Component {...props} context={value} />}
      </StoreConsumer>
    );
  };
}

export default withRouter(StoreContext);
export { StoreProvider, StoreConsumer, StoreContext };
