import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";

export default function TagList({ tags, type }) {
  let alphabet = "#,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(
    ","
  );

  let letterIndex = [];
  for (let x in tags) {
    letterIndex.push(tags[x]["name"][0].toLowerCase());
  }

  let letterIndexA = letterIndex.indexOf("a");

  let nonAlphObj = [];
  for (let x = 0; x < letterIndexA; x++) {
    nonAlphObj.push(tags[x]);
  }

  return (
    <section>
      <div className="section-content-grid less-gap">
        {type === "designers" ? (
          (alphabet = alphabet.map((letter) => {
            return (
              <div id={letter} key={`${letter}-wrap`}>
                <div className="card-title-no-wrap extra-margin-large">{letter}</div>
                <div className="section-content-grid three less-row-gap border-outline">
                  {letter === "#"
                    ? nonAlphObj.map((tag) => {
                        return (
                          <Link
                            className="tag-capitalize cut"
                            to={
                              type === "designers"
                                ? `/stores?designers=${tag.slug}`
                                : `/stores?city=${tag.name}`
                            }
                            key={tag.id}
                          >
                            <p
                              key={tag.id}
                            >{`${tag.name} (${tag.storeCount})`}</p>
                          </Link>
                        );
                      })
                    : tags
                        .filter((tag) =>
                          tag.name.toLowerCase().startsWith(letter)
                        )
                        .map((tag) => {
                          return (
                            <Link
                              className="tag-capitalize cut"
                              to={
                                type === "designers"
                                  ? `/stores?designers=${tag.slug}`
                                  : `/stores?city=${tag.name}`
                              }
                              key={tag.id}
                            >
                              <p
                                key={tag.id}
                              >{`${tag.name} (${tag.storeCount})`}</p>
                            </Link>
                          );
                        })}
                </div>
              </div>
            );
          }))
        ) : (
          <div className="section-content-grid three">
            {tags.map((tag) => {
              return (
                <Link
                  className="tag-capitalize cut"
                  to={
                    type === "designers"
                      ? `/stores?designers=${tag.slug}`
                      : `/stores?city=${tag.name}`
                  }
                  key={tag.id}
                >
                  <Card key={tag.id} item={tag} />
                  {/* <p key={tag.id}>{tag.name}</p> */}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
}
