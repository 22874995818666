import React from "react";
import { withStoreConsumer } from "../context";
import Loading from "./Loading";
import TagList from "./TagList";

function TagContainer({ context, type }) {
  const { loading, designers, cities } = context;

  let alphabet = "#,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(
    ","
  );

  let tagType;

  if (type === "designers") {
    tagType = designers;
  } else if (type === "cities") {
    tagType = cities;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="section-wrap no-grid less-padding center">
      <div className="grid-spacer"></div>
      <h1 className="extra-margin-large">
        {type} - {tagType.length} Results
      </h1>
      {type === "designers" ? (
        <div className="letters-wrap extra-margin-large">
          {
            (alphabet = alphabet.map((letter) => {
              return (
                <a
                  href={`#${letter}`}
                  className="letter"
                  key={`${letter}-link`}
                >
                  {letter.toUpperCase()}
                </a>
              );
            }))
          }
        </div>
      ) : (
        ""
      )}
      <TagList tags={tagType} type={type} />
    </div>
  );
}

export default withStoreConsumer(TagContainer);
