import React, { Component } from "react";
import { StoreContext } from "../context";
import Section from "./Section";
import mapImg from "../images/canada-map.png";

export default class FeaturedCities extends Component {
  static contextType = StoreContext;
  render() {
    return (
      <Section
        // "" = one column, "three" = three columns
        content=""
        // "" = 1. description and 2. content side by side, "reversed" = side by side, reversed order, "vertical" = one after the other
        grid="reversed"
        // "" = automatically justified (for example, the sticky featured stores description would be at the top of section-description-wrap), "center" = content will be aligned to the middle of the div (.align)
        type="center"
        // "" = left justified, "sticky" = sticky, "center" = centered to the body (can combine "sticky" and "center")
        subType=""
        description="Near you, and across the country."
        link="/cities"
        btnStyle="secondary"
        btnText="See all cities"
      >
        {/* <div><img src={mapImg}/></div> */}
        <img className="map-img" src={mapImg} alt="Map of Canada" />
      </Section>
    );
  }
}
