import styled from "styled-components";
import defaultBcg from "../images/PlaceholderBG.jpg";

const StyledPreviewImage = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.previewHeight === "large" ? "400px" : props.previewHeight === "medium" ? "240px" : "160px")};
  margin-bottom: 32px;
  background-image: var(--imageGradientWebkit),
    url(${(props) => (props.img ? props.img : defaultBcg)});
  background-image: var(--imageGradient),
    url(${(props) => (props.img ? props.img : defaultBcg)});
  background-position: 50% 50%;
  background-size: cover;
  @media only screen and (max-width: 1200px) {
    height: 344px;
  }
`;

export default StyledPreviewImage;
