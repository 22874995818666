import React, { Component } from "react";
import { StoreContext } from "../context";
import Loading from "./Loading";
import Section from "./Section";
import Card from "./Card";
import { Link } from "react-router-dom";

export default class FeaturedDesigners extends Component {
  static contextType = StoreContext;
  state = {
    windowWidth: window.innerWidth,
  };

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    let { loading, featuredDesigners: designers } = this.context;
    if (this.state.windowWidth < 992 && this.state.windowWidth > 704) {
      designers = designers.slice(0, 6);
    } else if (this.state.windowWidth < 704) {
      designers = designers.slice(0,3)
    }
    designers = designers.map((designer) => {
      return (
        <Link
          className="card-link"
          to={`/stores?designers=${designer.slug}`}
          key={designer.id}
        >
          <Card key={designer.id} item={designer} />
        </Link>
      );
    });
    return (
      <Section
        // "" = one column, "three" = three columns
        content="three"
        // content="three"
        // "" = 1. description and 2. content side by side, "reversed" = side by side, reversed order, "vertical" = one after the other
        grid="vertical"
        // "" = automatically justified (for example, the sticky featured stores description would be at the top of section-description-wrap), "center" = content will be aligned to the middle of the div (.align)
        type="center"
        // "" = left justified, "sticky" = sticky, "center" = centered to the body (can combine "sticky" and "center")
        subType="center"
        description="Over 100 curated designers to search from."
        link="/designers"
        btnStyle="secondary"
        btnText="See all designers"
      >
        <>{loading ? <Loading /> : designers}</>
      </Section>
    );
  }
}
