import React from "react";
import Card from "./Card";
import Button from "./Button";
import PropTypes from "prop-types";
import StyledPreviewImage from "./StyledPreviewImage";
import { Link } from "react-router-dom";

export default function Store({ store, previewHeight }) {
  const { slug, images } = store;
  return (
    <article className="card-wrap">
      <Link to={`/stores/${slug}`}>
        <StyledPreviewImage previewHeight={previewHeight} img={images[0]}></StyledPreviewImage>
      </Link>
      <Card item={store}>
        <Button link={`/stores/${slug}`} btnText="Details" />
      </Card>
    </article>
  );
}

Store.propTypes = {
  store: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};
