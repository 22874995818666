import React, { useState } from "react";
// import React, { useState, useEffect } from "react";
import Store from "./Store";
import Pagination from "./Pagination";
// import { useHistory, useLocation } from "react-router-dom";

export default function StoreList({ stores }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [storesPerPage] = useState(10); // Change to 10

  const indexOfLastStore = currentPage * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = stores.slice(indexOfFirstStore, indexOfLastStore);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const location = useLocation();
  // const history = useHistory();
  // const path = window.location.pathname;
  // let search = window.location.search;

  // console.log(search.includes("?page="));
  // console.log(search.replace(`?page=${currentPage}`, ""));

  // console.log(search);
  // // console.log(`${path}${search}?page=${currentPage}`)
  // let page = "";
  // if (search === "") {
  //   page = `?page=${currentPage}`;
  // } else {
  //   page = `&page=${currentPage}`;
  // }
  // console.log(`${path}${search}${page}`);

  // useEffect(() => {
  //   //   history.push(`${path}${search}&page=${currentPage}`);
  //   history.push(`${path}${search}${page}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage, history, path]);

  return (
    <section>
      <div className="section-content-grid two">
        {currentStores.map((item) => {
          return <Store key={item.id} store={item} previewHeight={"large"} />;
        })}
      </div>
      <Pagination
        storesPerPage={storesPerPage}
        totalStores={stores.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </section>
  );
}
