import React from "react";
import Banner from "../components/Banner";
import Button from "../components/Button";
import StyledHero from "../components/StyledHero";

export const Error = () => {
  return (
    <StyledHero>
      <Banner title="404" subtitle="Page not found">
        <Button to="/" btnText="Return home"></Button>
      </Banner>
    </StyledHero>
  );
};
