import React from "react";
import "./App.css";

import { Home } from "./pages/Home";
import {Stores} from "./pages/Stores";
import {Designers} from "./pages/Designers";
import {Cities} from "./pages/Cities";
import SingleStore from "./pages/SingleStore";
import { Error } from "./pages/Error";

import { Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/stores" component={Stores}/>
        <Route exact path="/designers" component={Designers}/>
        <Route exact path="/cities" component={Cities}/>
        <Route exact path="/stores/:slug"  component={SingleStore} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;